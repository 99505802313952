'use client'

import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, useToast, Card, Text, CardHeader, Heading, Box, Tooltip } from "@chakra-ui/react";
import AnnouncementChatList from './AnnouncementChatList'
import MessageComposer from './MessageComposer'
import AnnouncementConfirmationModal from './AnnouncementConfirmationModal'
import { fetchChats, sendAnnouncement } from '../mock/chat-list'
import confetti from 'canvas-confetti'
import axiosInstance from '../actions/utils/axiosInstance';
// import AppLayout from '../components/utils/layouts/AppLayout';

import { getCarrierChatGroupVisualizer, getAnnouncementLimits } from '../actions/chatVisualizerAction';



export default function AnnouncementCreator() {
  const dispatch = useDispatch();

  const [selectedChats, setSelectedChats] = useState([])
  const [message, setMessage] = useState('')
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [isSending, setIsSending] = useState(false);
  const toast = useToast()


  const { chats, loading } = useSelector((state) => state.carrierChatGroupVisualizer);
  const { announcementLimits, announcementLimitsLoading } = useSelector((state) => state.announcementLimits);
  const { userDetails } = useSelector((state) => state.userDetails);
  const companyId = userDetails?.company?.id;

  useEffect(() => {
    // If userDetails is not available, do nothing
    if (!userDetails) return;
    // Dispatch action to get chat group visualizer data
    dispatch(getCarrierChatGroupVisualizer(companyId));
    dispatch(getAnnouncementLimits());
  }, [userDetails]);


  const handleSend = () => {
    setIsConfirmationOpen(true)
  }

  const confirmSend = async () => {
    setIsConfirmationOpen(false)
    setIsSending(true)

    const payload = {
      message,
      chat_ids: selectedChats,
      assistant_id: null,
    };

    try {
      const url = '/api/v1/messaging/blast-carrier-messages/send';
      const response = await axiosInstance.post(url, payload);

      console.log(response);
      console.log(response.ok);
      if (!response.status === 200) {
        throw new Error(`Failed to send message: ${response.statusText}`);
      }

      // const result = await response.json();

      toast({
        title: "Anuncio enviado!",
        description: response.statusText,
      })

      // Trigger confetti effect
      confetti({
        particleCount: 100,
        spread: 70,
        origin: { y: 0.6 },
      })

      // Reset state after sending
      setSelectedChats([])
      setMessage('')
      dispatch(getAnnouncementLimits());
    } catch (error) {
      console.error('Failed to send announcement:', error)
      toast({
        title: "Error",
        description: error.message || "Failed to send announcement. Please try again.",
        variant: "destructive",
      })
    } finally {
      setIsSending(false)
    }
  }

  const getDisabledReason = () => {
    if (!announcementLimits?.can_send) {
      const translationDict = {
        daily: "diario",
        weekly: "semanal",
        monthly: "mensual",
      };
      return `Consumo ${translationDict[announcementLimits?.exceeded_period]} excedido`;
    }
    if (selectedChats.length === 0) {
      return "Seleccione al menos un chat";
    }
    if (message.trim() === '') {
      return "Escriba un mensaje";
    }
    return "";
  };

  return (

    <div className="container mx-auto p-4">
      <Card 
        className="w-full max-w-6xl mx-auto"
        bg={'gray.200'}
        p={ '15px' }
        color={'gray.700'}
        >
        <CardHeader>
          <Heading>Crear Mensaje Masivo</Heading>
        </CardHeader>
        {/* If announcementLimits is not loading set announcementLimits else show loading*/}
        {announcementLimitsLoading ? (
          <Text>Loading announcement limits...</Text>
        ) : announcementLimits ? (
          // Add a horizontal div with Flex that shows the consumption of the announcement limits for each period
        <Box>


          <Box 
            display="flex" 
            justifyContent="space-between" 
            bg="gray.100" 
            p={4} 
            borderRadius="md"
          >

              {Object.entries(announcementLimits.limits).map(([period, data]) => {
                const isExceeded = data.count >= data.limit;
                const periodNames = {
                  daily: 'Diario',
                  weekly: 'Semanal',
                  monthly: 'Mensual',
                };

                return (
                  <Box 
                    key={period}
                    textAlign="center"
                    p={3}
                    pb={0}
                    bg={isExceeded ? 'red.50' : 'transparent'}
                    borderRadius="md"
                    border={isExceeded ? '2px solid' : 'none'}
                    borderColor="red.500"
                  >
                    <Text 
                      fontWeight="bold"
                      color={isExceeded ? 'red.600' : 'gray.700'}
                    >
                      {periodNames[period]}
                    </Text>
                    <Text 
                      color={isExceeded ? 'red.600' : 'gray.700'}
                      fontWeight={isExceeded ? 'bold' : 'normal'}
                    >
                      {data.count} / {data.limit}
                    </Text>
                    {isExceeded && (
                      <Text 
                        fontSize="sm" 
                        color="red.600"
                        mt={0.5}
                      >
                        ¡Límite excedido!
                      </Text>
                    )}
                  </Box>
                );
              })}
          </Box>
        </Box>

        ) : null}


        <Text className="flex flex-col md:flex-row gap-6">
          <Box
            bg={'gray.100'}
            color={'gray.700'}
            p={'15px'}
            mb = {4}
          >
          <div className="w-full md:w-1/2 space-y-4">
            <MessageComposer
              message={message}
              setMessage={setMessage}
            />
            <Box mt={4}>
              {/* <div className="text-sm">
                Data: selectedChats length is 0: {selectedChats.length === 0},  messages trimed: {message.trim() === ''} ,isSending: {isSending}
              </div> */}

              <Tooltip 
                label={getDisabledReason()} 
                isDisabled={!getDisabledReason()}
              >
                <Button 
                  bg={'gray.700'}
                  color={'gray.100'}
                  _hover={{
                    bg: 'gray.500', // Darker background on hover
                    color: 'gray.50', // Keep the text color consistent
                  }}
                  onClick={handleSend} 
                  isDisabled={
                    selectedChats.length === 0 ||
                    message.trim() === '' ||
                    isSending ||
                    !announcementLimits?.can_send
                  }

                  className="w-full"
                >
                  {isSending ? 'Enviando...' : 'Enviar Mensaje'}
                </Button>
              </Tooltip>
            </Box>
          </div>
          </Box>
          <div className="w-full md:w-1/2">
            <AnnouncementChatList
              chats={chats || []} 
              selectedChats={selectedChats}
              setSelectedChats={setSelectedChats}
              isLoading={loading}
            />
          </div>
        </Text>
      </Card>
      <AnnouncementConfirmationModal
        isOpen={isConfirmationOpen}
        onClose={() => setIsConfirmationOpen(false)}
        onConfirm={confirmSend}
        chatsCount={selectedChats.length}
      />
    </div>

  )
}

