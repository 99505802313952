import React, { useState } from 'react'
import { Box, Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, useToast } from '@chakra-ui/react'
import styled from "@emotion/styled";
import DataTable from './Table';
import JSON5 from 'json5';
import Chat from './Chat';
import MessageBubble from './MessageBubble';
import Snippets from './Snippets';
import { createExample } from "./../../api";


const Container = styled(Box)`
  display:grid;
  grid-template-rows: auto 1fr;
`
const Content = styled(Box)`
  box-sizing: border-box;
  display:grid;
  gap: 1em;
  grid-template-columns: calc(50% - 1em) calc(50% - 1em);
  justify-content: space-between;
  overflow-y: auto;
  max-height: 700px;
  align-items: end;
  padding: 1em;
  position: relative;
`;

const Section = styled(Box)`
  position: sticky;
  top: 0;
  display:flex;
  height: 100%;
  flex-direction: column;
  gap: 1em;
  justify-content: end;
`

const PipelineEvent = ({ event }) => {
  const toast = useToast();
  const getPromptImages = (prompt) => {
    return prompt?.adjusted_image_urls
  }
  const getTables = (prompt) => {
    return prompt?.tables
  }

  const [modalData, setModalData] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const getResponse = (response) => {
    const cleaned = { ...response };
    delete cleaned.raw_prompt_input;
    delete cleaned.response_text;
    delete cleaned.token;
    delete cleaned.env;
    delete cleaned.response;
    delete cleaned.trips_founds;
    delete cleaned.trips_found;
    return cleaned
  };

  const getPromptInputData = (prompt) => {
    return prompt?.inputData
  }

  const getRawPromptInput = (response) => {
    return response?.raw_prompt_input
  }

  const saveExample = () => {
    console.log("saveExample", modalData);
    console.log("headers", lastRawPromptInput);

    const example = {
      "message": lastRawPromptInput.human, 
      "response": {
        "response": true, 
        "trips_founds": modalData.trip_requests.length, 
        "trip_requests": modalData.trip_requests,
      }, 
      "image_data": {labels: [], tables: [], raw_text: []}, 
      "context_blob": "",
    }

    const payload = {
      "json": JSON.stringify(example),
    }

    createExample(payload);

    toast({
      title: "Example saved.",
      description: "Your example has been saved successfully.",
      status: "success", 
      duration: 5000,
      isClosable: true,
    });
  }


  const getPromptData = (prompt) => {
    try {
      const promptData = JSON5.parse(prompt?.data)
      return promptData

    } catch (e) {
      const promptData = prompt?.data;
      return promptData

    }
  }

  const getOutputFormat = (prompt) => {
    return prompt?.output_headers ? sortKeysByValues(prompt?.output_headers) : null
  }

  const sortKeysByValues = (obj) => {
    const entries = Object.entries(obj);
    entries.sort((a, b) => a[1] - b[1]);
    return entries.map(entry => entry[0]);
  }

  const promptData = getPromptData(event?.prompt);
  const response = getResponse(event?.response);
  const adjustedImageUrls = getPromptImages(event?.prompt)?.flat()
  const tables = getTables(event?.prompt)?.flat()
  const rawPromptInput = getRawPromptInput(event?.response)?.flat()
  const headers = getOutputFormat(event?.prompt)
  const lastRawPromptInput = rawPromptInput ? rawPromptInput[rawPromptInput.length - 1] : null
  const inputData = getPromptInputData(event?.prompt)
  const shouldFallBackToLastRawPromptInput = !promptData && !inputData && lastRawPromptInput && !adjustedImageUrls?.length

  const [isSnippetsOpen, setIsSnippetsOpen] = useState(false)

  return (
    <Container>
      {rawPromptInput && <Chat messages={rawPromptInput} />}

      <Content>
        <Section style={{ alignSelf: "start", justifyContent: "start" }}>
          {adjustedImageUrls?.length > 0 && adjustedImageUrls.map((url, index) => (
            <div style={{ position: "sticky", alignSelf: "start", top: "0px", maxHeight: "100%" }} key={index}>
              <a href={url} target="_new" rel="noopener noreferrer">
                <img src={url} alt={`prompt-${index}`} />
              </a>
            </div>
          ))}

          {promptData instanceof Object && <DataTable title={Object.keys(response).flat().join(" ")} data={[...Object.values(promptData)].flat()} />}
          {shouldFallBackToLastRawPromptInput && (<MessageBubble isLast type={Object.keys(lastRawPromptInput).join("")} content={Object.values(lastRawPromptInput).join("")} />)}
          {inputData && inputData.map((data, index) => {
            return <DataTable key={index} title={`OCR table Recognition ${index + 1}`} data={data.flat()} />
          })}
        </Section>
        <Section>
          {tables?.length > 0 && tables.map((table, index) => (
            <DataTable key={index} title={`OCR table Recognition ${index + 1}`} data={table} />
          ))}
          <DataTable headers={headers} title={Object.keys(response).flat().join(", ")} data={[...Object.values(response)].flat()} />
        </Section>
        <Button 
            onClick={() => {
              setModalData(response)
              setIsModalOpen(true);
            }} 
            colorScheme="blue" 
            size="md" 
            mt={4}
          >
            Create Example
          </Button>
      </Content>
      <Snippets snippets={event.prompt_snippets} isOpen={isSnippetsOpen} onClose={() => setIsSnippetsOpen(false)} />
      {isModalOpen && <Modal isCentered style={{ width: "100%", height: "60%", maxWidth: "100%" }} isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <ModalOverlay />
        <ModalContent style={{ overflowY: "auto", maxWidth: "90%", height: "90%" }}>
          <ModalHeader>Create Example</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <div style={{ position: "sticky", top: 0, zIndex: 1, backgroundColor: "white" }}>
              <MessageBubble isLast type={Object.keys(lastRawPromptInput).join("")} content={Object.values(lastRawPromptInput).join("")} />
            </div>
            <DataTable isEditable={true} headers={headers} title={Object.keys(modalData).flat().join(", ")}  data={[...Object.values(modalData)].flat()} />
            <Button mt={4} onClick={() => {
              saveExample();
            }} >
              Save Example
            </Button>
          </ModalBody>
        </ModalContent>
      </Modal>}
    </Container>
  )
}

export default PipelineEvent;