import axiosInstance from './actions/utils/axiosInstance';

export async function fetchContentTypes(searchQuery = '') {
  const url = `/api/v1/content_types?search=${encodeURIComponent(searchQuery)}`;
  const response = await axiosInstance.get(url);
  console.log({ fetchContentTypes: response });
  return await response.data
}

export async function fetchObjectsForContentType(contentTypeId, searchQuery = '') {
  const url = `/api/v1/content_types/${contentTypeId}/objects?search=${encodeURIComponent(searchQuery)}`;
  const response = await axiosInstance.get(url);
  return await response.data
}


// New functions for PromptSnippet CRUD

// Create a new PromptSnippet
// Data should be an object containing:
// { content_type, object_id, template_key, enabled, fallback, content }
// content_type and object_id can be null or omitted if snippet is global.
export async function createPromptSnippet(data) {
  const url = '/api/v1/prompt_snippets/';
  const response = await axiosInstance.post(url, data);
  return response.data;
}

// Update an existing PromptSnippet
// Provide the snippet's ID and the updated data (same fields as createPromptSnippet)
export async function updatePromptSnippet(id, data) {
  const url = `/api/v1/prompt_snippets/${id}/`;
  const response = await axiosInstance.put(url, data);
  return response.data;
}

// Optionally, you can add more functions as needed:
// Get a single PromptSnippet by ID
export async function getPromptSnippet(id) {
  const url = `/api/v1/prompt_snippets/${id}/`;
  const response = await axiosInstance.get(url);
  return response.data;
}

// List all PromptSnippets (optional, depending on your needs)
export async function listPromptSnippets() {
  const url = '/api/v1/prompt_snippets/';
  const response = await axiosInstance.get(url);
  return response.data;
}

// Delete a PromptSnippet (optional)
export async function deletePromptSnippet(id) {
  const url = `/api/v1/prompt_snippets/${id}/`;
  await axiosInstance.delete(url);
  return true;
}

export async function createExample(data) {
  const url = `/api/v1/examples/create/`;
  const response = await axiosInstance.post(url, data);
  return response.data; 
}