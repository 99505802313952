import { useState, useRef } from 'react';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Button,
} from "@chakra-ui/react";

export default function AnnouncementConfirmationModal({ isOpen, onClose, onConfirm, chatsCount }) {
  const [isLoading, setIsLoading] = useState(false);
  const cancelRef = useRef(); // Chakra AlertDialog requires a reference for the least destructive action

  const handleConfirm = () => {
    setIsLoading(true);
    setTimeout(() => {
      onConfirm();
      setIsLoading(false);
    }, 1000); // Simulating a delay for the sending process
  };

  return (
    <AlertDialog

      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
    >
      <AlertDialogOverlay>
        <AlertDialogContent
            bg={'gray.100'}
            color={'gray.700'}
        >
          <AlertDialogHeader>Confirmar Anuncio Masivo</AlertDialogHeader>
          <AlertDialogBody>
            ¿Confirmas que quieres enviar este anuncio a {chatsCount} chat{chatsCount !== 1 ? 's' : ''}? Esta acción no se puede deshacer.
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button 
                ref={cancelRef} 
                onClick={onClose} 
                variant="ghost"
                bg={'gray.350'}
                color={'gray.500'}
            >
              Cancelar
            </Button>
            <Button
              colorScheme="blue"
              onClick={handleConfirm}
              isLoading={isLoading}
              ml={3}
            >
              Confirmar
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}
