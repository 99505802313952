import React, { useState } from 'react';
import {
  TableContainer,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
} from '@chakra-ui/react';
import styled from '@emotion/styled';

const CustomContainer = styled(TableContainer)`
  max-height: 100%;
  box-sizing: border-box;
  overflow-y: auto;
`;

const DataTable = ({ data, headers, title = "", isEditable = false }) => {
  const [updatedRows, setUpdatedRows] = useState(null);

  // Early return for unsupported data formats
  if (!Array.isArray(data) && (typeof data !== 'object' || data === null)) {
    return <div>Data format not supported</div>;
  }

  // Handle empty data array
  if (Array.isArray(data) && data.length === 0) {
    return null;
  }

  const handleAddRow = () => {
    if (!updatedRows) {
      setUpdatedRows([...(rows || []), Array(rows[0].length).fill('')]);
    } else {
      setUpdatedRows([...(updatedRows || []), Array(updatedRows[0].length).fill('')]);
    }
  };

  const handleRemoveRow = (rowIndex) => {
    if (!updatedRows) {
      setUpdatedRows(rows.filter((_, index) => index !== rowIndex));
    } else {
      setUpdatedRows(updatedRows.filter((_, index) => index !== rowIndex));
    }
  };

  let processedHeaders = headers || [];
  let rows = [];

  if (Array.isArray(data)) {
    if (Array.isArray(data[0])) {
      // Data is a list of lists (rows)
      const maxColumns = Math.max(...data.map(row => row.length));
      rows = data.map(row => [
        ...row,
        ...Array(maxColumns - row.length).fill(''),
      ]);
    } else if (typeof data[0] === 'object' && data[0] !== null) {
      // Data is a list of objects
      const allKeys = Array.from(
        data.reduce((keys, obj) => {
          Object.keys(obj).forEach(key => keys.add(key));
          return keys;
        }, new Set())
      );
      // If headers not provided, use all keys
      if (!processedHeaders.length) {
        processedHeaders = allKeys;
      }

      rows = data.map(obj =>
        processedHeaders.map(key => (obj[key] !== undefined ? obj[key] : ''))
      );
    } else {
      // Data is a list of primitives
      rows = data.map(value => [value]);
      if (!processedHeaders.length) {
        processedHeaders = [""];
      }
    }
  } else {
    // Data is a single object
    const objKeys = Object.keys(data);
    if (!processedHeaders.length) {
      processedHeaders = objKeys;
    }
    rows = [processedHeaders.map(key => (data[key] !== undefined ? data[key] : ''))];
  }

  const handleCellChange = (rowIndex, cellIndex, newValue) => {
    if (isEditable) {
      if (!updatedRows) {
        setUpdatedRows(rows);
      } else {
        rows = [...updatedRows];
        rows[rowIndex][cellIndex] = newValue;
        setUpdatedRows(rows);
      }
    }
  };

  return (
    <CustomContainer>
      <Table variant="striped" colorScheme="blue" size="sm">
        <Thead>
          <Tr>
            <Th colSpan={processedHeaders.length} style={{ textTransform: "capitalize", fontSize: '1em' }}>
              {title.replaceAll("_", " ")}
            </Th>
          </Tr>
          {processedHeaders.length > 0 && (
            <Tr>
              {processedHeaders.map((header, index) => (
                <Th style={{ textTransform: "capitalize" }} key={index}>{header.replaceAll("_", " ")}</Th>
              ))}
            </Tr>
          )}
        </Thead>
        <Tbody>
        {updatedRows && updatedRows.map((row, rowIndex) => (
            <Tr key={rowIndex}>
            {isEditable && (
              <Td>
                <Button mt={0} size='xs' variant='outline' colorScheme='red' onClick={() => {
                  handleRemoveRow(rowIndex)
                }}>
                  Remove
                </Button>
              </Td>
            )}
            {row.map((cell, cellIndex) => {
                let displayValue;
                if (cell === null || cell === '') {
                  displayValue = ' - ';
                } else if (typeof cell === 'object') {
                  // Convert nested objects/arrays to string for readability
                  displayValue = <DataTable title='' data={cell} />;
                } else {
                  displayValue = cell;
                }

                return (
                  <Td key={cellIndex}>
                    {isEditable ? (
                      <input
                        type="text"
                        value={displayValue}
                        onChange={(e) => handleCellChange(rowIndex, cellIndex, e.target.value)}
                        style={{ backgroundColor: 'transparent', border: 'none', outline: 'none' }}
                      />
                    ) : (
                      displayValue
                    )}
                  </Td>
                );
              })}
            </Tr>
          ))}
          {!updatedRows && rows.map((row, rowIndex) => (
            <Tr key={rowIndex}>
            {isEditable && (
              <Td>
                <Button mt={0} size='xs' variant='outline' colorScheme='red' onClick={() => {
                  handleRemoveRow(rowIndex)
                }}>
                  Remove
                </Button>
              </Td>
            )}
              {row.map((cell, cellIndex) => {
                let displayValue;
                if (cell === null || cell === '') {
                  displayValue = ' - ';
                } else if (typeof cell === 'object') {
                  // Convert nested objects/arrays to string for readability
                  displayValue = <DataTable title='' data={cell} />;
                } else {
                  displayValue = cell;
                }

                return (
                  <Td key={cellIndex}>
                    {isEditable ? (
                      <input
                        type="text"
                        value={displayValue}
                        onChange={(e) => handleCellChange(rowIndex, cellIndex, e.target.value)}
                        style={{ backgroundColor: 'transparent', border: 'none', outline: 'none' }}
                      />
                    ) : (
                      displayValue
                    )}
                  </Td>
                );
              })}
            </Tr>
          ))}
          {isEditable && (
            <Tr>
              <Td colSpan={processedHeaders.length + 1}>
                <button onClick={handleAddRow}>Add Row</button>
              </Td>
            </Tr>
          )}
        </Tbody>
      </Table>
    </CustomContainer>
  );
};

export default DataTable;