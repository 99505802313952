import React from 'react';

import AppLayout from '../components/utils/layouts/AppLayout';
import AnnouncementComponent from '../components/AnnouncementComponent';

function AnnouncementScreen() {

  return (
    <AppLayout>
      <AnnouncementComponent />
    </AppLayout>
  );
}

export default AnnouncementScreen;
